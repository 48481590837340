export const GET_CLASSES = 'GET_CLASSES'
export const GET_CLASS = 'GET_CLASS'
export const REDUX_GET_CLASSES = 'REDUX_GET_CLASSES'
export const DELETE_CLASS = 'DELETE_CLASS'
export const ADD_CLASS = 'ADD_CLASS'
export const DELETE_MANY_CLASSES = 'DELETE_MANY_CLASSES'
export const UPDATE_CLASS = 'UPDATE_CLASS'
export const ADD_CLASS_RESOURCE = 'ADD_CLASS_RESOURCE'
export const GET_CLASS_RESOURCES = 'GET_CLASS_RESOURCES'
export const GET_CLASS_STUDENTS = 'GET_CLASS_STUDENTS'
export const GET_CLASS_SUBJECTS = 'GET_CLASS_SUBJECTS'
export const DELETE_RESOURCE = 'DELETE_RESOURCE'
export const UPDATE_CLASS_SEQUENCE = 'UPDATE_CLASS_SEQUENCE'
export const GENERATE_CLASS_STREGNTH_LIST = 'GENERATE_CLASS_STREGNTH_LIST'
export const PROMOTE_STUDENTS = 'PROMOTE_STUDENTS'
export const GET_PREVIOUS_CLASSES = 'GET_PREVIOUS_CLASSES'
export const ASSIGN_TEACHERS = 'ASSIGN_TEACHERS'
