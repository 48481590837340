import {
  GET_CLASS,
  GET_CLASSES,
  DELETE_CLASS,
  ADD_CLASS,
  DELETE_MANY_CLASSES,
  UPDATE_CLASS,
  ADD_CLASS_RESOURCE,
  GET_CLASS_RESOURCES,
  GET_CLASS_STUDENTS,
  GET_CLASS_SUBJECTS,
  DELETE_RESOURCE,
  UPDATE_CLASS_SEQUENCE,
  GENERATE_CLASS_STREGNTH_LIST,
  PROMOTE_STUDENTS,
  GET_PREVIOUS_CLASSES,
  ASSIGN_TEACHERS,
} from 'setup/redux/Actions/Class/ActionTypes/ApiActionTypes'
import {takeEvery} from 'redux-saga/effects'
import fetchData from '../../SagaHelper'

function* dataSaga() {
  yield takeEvery(GET_CLASSES, fetchData)
  yield takeEvery(GET_CLASS, fetchData)
  yield takeEvery(DELETE_CLASS, fetchData)
  yield takeEvery(ADD_CLASS, fetchData)
  yield takeEvery(DELETE_MANY_CLASSES, fetchData)
  yield takeEvery(UPDATE_CLASS, fetchData)
  yield takeEvery(ADD_CLASS_RESOURCE, fetchData)
  yield takeEvery(GET_CLASS_RESOURCES, fetchData)
  yield takeEvery(GET_CLASS_STUDENTS, fetchData)
  yield takeEvery(GET_CLASS_SUBJECTS, fetchData)
  yield takeEvery(DELETE_RESOURCE, fetchData)
  yield takeEvery(UPDATE_CLASS_SEQUENCE, fetchData)
  yield takeEvery(GENERATE_CLASS_STREGNTH_LIST, fetchData)
  yield takeEvery(PROMOTE_STUDENTS, fetchData)
  yield takeEvery(GET_PREVIOUS_CLASSES, fetchData)
  yield takeEvery(ASSIGN_TEACHERS, fetchData)
}

export default dataSaga
